import React from "react"
import { H1 } from "../components/headings"
import { Typography } from "../components/typography"

export default function NotFound() {
	return (
		<>
			<H1>Not found!</H1>
			<Typography>
				You just found a page that doesn't exist.. the sadnesses!
			</Typography>
		</>
	)
}
